import React from "react";
import Footer from '../components/Footer';
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet";

class einClickPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <Helmet>
                    <html className='has-navbar-fixed-top' />
                    <title>Ein click</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="hero is-light default-hero">
                    <div className="hero-image">
                        <picture>
                            <source media="(max-width:768px)" srcSet='/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-mobile2.jpg'/>
                            <source media="(max-width:1024px)" srcSet='/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg' />
                            <source media="(max-width:1216px)" srcSet='/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg' />
                            <source media="(min-width:1217px)" srcSet='/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg'vb />
                            <img src='/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg' style={{width:'auto'}} alt='MeinAlarm24: Deutschlands Nr.1 für Sicherheitstechnik' title='MeinAlarm24: Deutschlands Nr.1 für Sicherheitstechnik'/>
                        </picture>
                    </div>
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <div className="hero-box">
                                <div className="price-calculator-columns" >
                                    <div className="box price-box is-half is-offset-one-quarter" style={{overflow: 'hidden'}}>
                                        <article className="media price-calculator">
                                            <div className="media-content">
                                                <div className="thanks" key="thanks">
                                                    <Fade right>
                                                        <div className="columns thanks-top is-mobile">
                                                            <div className="column is-narrow thanks-top-left has-text-centered">
                                                                <i className="fas fa-check"></i>
                                                            </div>
                                                            <div className="column is-vertical-center thanks-top-middle">
                                                                <span className='is-size-4-tablet'>Herzlichen Dank für Ihre Anfrage!</span>
                                                                <div className="arrow-down">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="content has-text-centered">
                                                            <div className="columns">
                                                                <div className="column">
                                                                    <figure className="image customer-agent">
                                                                        <img src="/img/image_2_kostenlose_beratung.svg" alt="Kostenlose Beratung"/>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <p className="is-size-5"><strong>Wie geht es weiter?</strong></p>
                                                            <p className="is-size-5">Ihre Anfrage für eine kostenlose Beratung wurde erfolgreich an uns übermittelt. Vielen Dank! Ihr MeinAlarm24 Sicherheitsberater wird sich um Ihr Anliegen kümmern und sich telefonisch mit Ihnen in Verbindung setzen.</p>
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default einClickPage;

